<template lang="html">
<div></div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>


<script>
import Cookie from 'js-cookie'
export default {
  name: 'logout',
  created(){
    Cookie.remove(process.env.VUE_APP_AUTH_COOKIE_NAME);
    this.$store.state.user = false;
    this.$router.push({name:'Login'});
  }
}
</script>
